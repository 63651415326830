import React from "react";
import { graphql, withPrefix } from "gatsby";
import SEO from "../components/SEO";
import Layout from "../layouts/index";
import Newsletter from "../components/Newsletter";
// import cover from "../images/about/differences.jpg";

const Service = ({ data }) => {
  const { title } = data.markdownRemark.frontmatter;
  const { html } = data.markdownRemark;
  const { image } = data.markdownRemark.frontmatter;

  return (
    <Layout bodyClass="page-service">
      <SEO title={title} />
      <div className="cover-container">
        <div className="cover-img">
          {/* <img className= "blog-img" alt="blog post cover" src={withPrefix(edge.node.frontmatter.image)} /> */}
          <img className="blog-img" src={image} />
        </div>
        <div className="cover-content">
          <div>
            <h1 className="hero-title">{title}</h1>
          </div>
        </div>
      </div>
      <div className="strip strip-white mt-0 pb-5">
        <div className="container pt-4 pt-md-10">
          <div className="row justify-content-start">
            <div className="col-12 col-md-8">
              <div className="service service-single">
                {/* <h1 className="title">{title}</h1> */}
                <div
                  className="content"
                  dangerouslySetInnerHTML={{ __html: html }}
                />
              </div>
            </div>
          </div>
          <a href="/media" className="go-back link">
            <span className="back-arrow" /> Back to all Media
          </a>
        </div>
      </div>
      <Newsletter />
    </Layout>
  );
};

export const query = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        path
        image
      }
      html
    }
  }
`;

export default Service;
